import debugLoader from "./utils/debugging";
import { extend } from "./utils/objects";
import { fadeIn, fadeOut } from "./utils/animations";
import { onSubSelector, ready, on } from "./utils/events";

export default class KOLResponses {
  constructor(optionOverrides) {
    let responseTemplate = KOL.fetchKolScriptAttribute(
      "data-checkout-response-template"
    );

    let responsePageOverrideId = KOL.fetchKolScriptAttribute(
      "data-response-page-id"
    );

    this.options = extend({}, this.defaultOptions, optionOverrides);

    this.debug = debugLoader(this.options.debug);

    this.options.responses.template = responseTemplate;
    this.options.responses.pageOverrideId = responsePageOverrideId;

    console.log(this.options);

    this.debug("The Current ResponseOptions", this.options);
  }

  defaultOptions = {
    responses: {
      template: null,
      animationSpeed: 1000
    }
  };

  execResponse() {
    if (
      this.options.responses.template &&
      this[this.options.responses.template]
    ) {
      this[this.options.responses.template]();
    }
  }

  close_kol_overlay() {
    const kol_overlay = document.querySelector("#kol_overlay");
    //TODO: Figure out how to read the options in here for things like speed.
    fadeOut(kol_overlay, 1000);
  }

  /* Template Functions */
  sideBar() {
    const buildSidebar = () => {
      let lead_data = this.options.lead_data;

      const kol_overlay = document.createElement("div");

      Object.assign(kol_overlay, {
        id: "kol_overlay"
      });

      let overlayWidth = "450px";
      let overlayHeight = "100%"

      const width =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;

      if (width < 769) {
        overlayWidth = "100%";
        overlayHeight = "50%";
      }
      Object.assign(kol_overlay.style, {
        position: "absolute",
        bottom: "0px",
        width: overlayWidth,
        "z-index": "99",
        right: "0px",
        height: overlayHeight,
        opacity: "0",
        "box-shadow": "0 0 6px #000",
        "background-color": "transparent",
      });

      const kol_overlay_close = document.createElement("div");

      Object.assign(kol_overlay_close, {
        id: "kol_overlay_close",
        class: "kol-overlay-close"
      });

      Object.assign(kol_overlay_close.style, {
        "border-bottom-left-radius": "5px",
        color: "#ffffff",
        "background-color": "#000000",
        display: "inline-block",
        padding: "5px 10px",
        position: "absolute",
        top: "0px",
        right: "0px",
        cursor: "pointer"
      });

      kol_overlay_close.innerText = "X";

      kol_overlay_close.addEventListener("click", this.close_kol_overlay);

      kol_overlay.appendChild(kol_overlay_close);

      const kol_iframe = document.createElement("iframe");

      Object.assign(kol_iframe.style, {
        width: "100%",
        height: "100%",
        border: "none"
      });

      kol_overlay.appendChild(kol_iframe);

      const handleLoad = () => {
        console.log("Frame Loaded");
        //fadeIn(kol_overlay, );

        //TODO: Figure out how to read the options in here for things like speed.
        fadeIn(kol_overlay, 1000);
      };

      kol_iframe.addEventListener("load", handleLoad, true);

      if (this.options.responses.pageOverrideId) {
        kol_iframe.src =
          "https://embed.kickoffpages.com/" +
          this.options.responses.pageOverrideId +
          "/?kolid=" +
          lead_data.social_id;
      } else {
        kol_iframe.src = lead_data.redirect_url;
      }

      document.body.appendChild(kol_overlay);
    };
    ready(buildSidebar);
  }
}
