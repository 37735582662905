import uuid from "./utils/uuid";
import Cookies from "./utils/cookies";
import getQS from "./utils/query_strings";

const uniqueId = campaignId => {
  const cookieKey = `kola.${campaignId}`;
  let uid = Cookies.get(cookieKey);
  if (!uid) {
    uid = uuid();
    Cookies.set(cookieKey, uid);
  }
  return uid;
};

const sessionId = campaignId => {
  const cookieKey = `kola.${campaignId}.session`;
  let sid = Cookies.get(cookieKey);
  if (!sid) {
    sid = uuid();
    Cookies.session(cookieKey, sid);
  }
  return sid;
};

const conversionId = campaignId => {
  const cookieKey = `kola.${campaignId}.cid`;
  const cid = getQS("kolid") || Cookies.get(cookieKey);
  if (cid) {
    Cookies.set(cookieKey, cid);
  }
  return cid;
};

const socialId = campaignId => {
  const cookieKey = `kola.${campaignId}.social_id`;
  const sid = getQS("kid") || Cookies.get(cookieKey);
  if (sid) {
    Cookies.set(cookieKey, sid);
  }
  return sid;
};

const currentUrl = () => getQS("__url") || window.location.toString();

const currentReferral = () => getQS("__ref") || document.referrer;

const isIframe = () => (window.top === window ? 0 : 1);

const createUser = (campaignId, source, custom = {}) => {
  const user = {
    rid: uuid(),
    uid: uniqueId(campaignId),
    sid: sessionId(campaignId),
    cid: conversionId(campaignId),
    kid: socialId(campaignId),
    url: currentUrl(),
    ref: currentReferral(),
    lid: campaignId,
    custom,
    source,
    if: isIframe()
  };
  return user;
};

export default createUser;
