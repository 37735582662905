import debugLoader from "./utils/debugging";
import createUser from "./analytics_user";
import uuid from "./utils/uuid";
import Cookies from "./utils/cookies";
import { extend } from "./utils/objects";
import toParam from "./utils/params";
import { capturedFunctionNotificationName } from "./utils/globals";
import { onSubSelector, ready, on } from "./utils/events";

export default class KOLAnalytics {
  constructor(optionOverrides) {
    this.optionOverrides = optionOverrides;
    this.options = extend(
      {},
      this.defaultOptions,
      optionOverrides,
      KOLAnalytics.globalOverrides()
    );
    this.debug = debugLoader(this.options.debug);
    this.debug("The Current Options", this.options);
    this.setup();
  }

  user = {};

  defaultOptions = {
    analytics: {
      statsUrl: "https://api.kickofflabs.com",
      web_view_path: "stats/b",
      click_track_path: "stats/t",
      stats_version: 1,
      trackViews: false
    },
    source: "koljs"
  };

  static globalOverrides() {
    return window.kol_analytics_options || {};
  }

  setup() {
    this.setUserProperties();
    this.trackUserInteraction();
    // wrap this.capture with event to maintain proper scope
    on("body", capturedFunctionNotificationName, event =>
      this.captureSignUp(event)
    );
    if (this.options.analytics.click_selector) {
      onSubSelector("body", ".kol", "click", event => {
        const aHref = event.target;
        const data = JSON.parse(aHref.dataset.kol) || {};
        data.url = data.url || aHref.getAttribute("href");
        this.trackClick(data);
      });
    }
  }

  trackClick(data) {
    const mergedData = extend({}, this.user, { click: data });
    const imageUrl = this.generateUrl(
      this.options.analytics.click_track_path,
      mergedData
    );
    KOLAnalytics.addImageToPage(imageUrl);
  }

  setUserProperties() {
    const { campaignId, source } = this.options;
    const { custom } = this.options.analytics;

    this.user = createUser(campaignId, source, custom);
  }

  trackUserInteraction() {
    this.mouseMoves = 0;
    this.keyDowns = 0;
    window.addEventListener("mousemove", () => {
      this.mouseMoves += 1;
    });
    window.addEventListener("keydown", () => {
      this.keyDowns += 1;
    });
  }

  getConversionData() {
    const newObject = {};
    /* eslint no-restricted-syntax: off */
    for (const key in this.user) {
      if (Object.prototype.hasOwnProperty.call(this.user, key)) {
        const item = this.user[key];
        if (item) {
          newObject[`__${key}`] = item;
        }
      }
    }
    return newObject;
  }

  setConversionData(formData) {
    const mergeFormData = new FormData(formData);

    for (const key in this.user) {
      if (Object.prototype.hasOwnProperty.call(this.user, key)) {
        const item = this.user[key];
        if (item) {
          mergeFormData.append(`__${key}`, item);
        }
      }
    }
    mergeFormData.append("__mm", this.mouseMoves);
    mergeFormData.append("__kd", this.keyDowns);
    return mergeFormData;
  }

  //TODO: Scott? CID vs SID VS ID
  captureSignUp(event) {
    this.debug("captureSignUp()", event);
    if (event.detail) {
      this.setConversionId(event.detail.id);
    } else {
      this.setConversionId(event.id);
    }
  }

  setConversionId(sid) {
    if (sid) {
      this.debug("setting conversion id", sid);
      this.user.cid = sid;
      Cookies.set(`kola.${this.options.campaignId}.cid`, sid);
    } else {
      this.debug("setConversionId called, but no conversion id");
    }
  }

  get trackViews() {
    return this.options.analytics.trackViews;
  }

  trackView(trackOptions = {}) {
    if (!this.trackedView) {
      this.debug("trackView()", trackOptions);
      extend(this.user.custom, this.user.custom, trackOptions.custom || {});
      this.logView();
      this.trackedView = true;
      return true;
    }
    this.debug("trackView()", "skipping. Already executed");
    return false;
  }

  logView() {
    this.debug("logView", "logging called");
    const imageUrl = this.generateUrl(
      this.options.analytics.web_view_path,
      this.user
    );
    this.debug("imageUrl", imageUrl);
    KOLAnalytics.addImageToPage(imageUrl);
  }

  generateUrl(path, data) {
    this.debug(`generateUrl(${path})`, data);
    const params = toParam(data);
    this.debug(`generateUrl(${path}) params`, params);
    return `${this.options.analytics.statsUrl}/${path}/${uuid()}?${params}`;
  }

  static addImageToPage(imageUrl) {
    const callback = () => {
      const img = document.createElement("img");
      img.setAttribute("style", "display:none");
      img.setAttribute("src", imageUrl);
      document.body.appendChild(img);
    };

    ready(callback);
  }
}
