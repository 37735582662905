import debugLoader from "./utils/debugging";
import { extend, extendOverwrite } from "./utils/objects";
import { fadeIn, fadeOut } from "./utils/animations";
import stringToHTML from "./utils/dom";
import { ready } from "./utils/events";
import KOLOneTemplates from "./templates";
import storage from "local-storage-fallback";

//TODO: Can this be split up as a constructor for each action?
//TODO: Right now all static feels odd... but what about multiple ones?
export default class KOLOnPage {
  constructor(options) {
    this.options = options;
    if (options && options[0]) {
      
      const keys = Object.keys(options[0]);
      for (const key of keys) {
        if (this[key]) {
          //TODO - wrapping for errors so the rest always works?
          this[key](options[0][key]);
        }
      }
    }
  }

  static getiFrameEmbedSrcUrl(options) {
    let domain = options["domain"] || "embed.kickoffpages.com";
    let query_string = `?__url=${encodeURIComponent(
      options["url"] || document.location
    )}`;
    let protocol = options["protocol"] || "https";
    let referrer = encodeURIComponent(
      options["referrer"] || document.referrer || ""
    );

    if (referrer.length > 0) {
      query_string = "#{query_string}&__ref=#{referrer}";
    }
    let existing_query_string = window.location.search;

    if (existing_query_string.length > 0) {
      query_string = `${query_string}&${existing_query_string.substring(1)}`;
    }

    return `${protocol}://${domain}/${options["pageID"]}/${query_string}`;
  }

  static handleContestLoad(event) {
    //remove the preview.
    console.log("debugger");
    console.log(event);
    KOLOnPage.getLeadInfo();
    let previewSpinner = document.querySelector("#kol-spinner-wrapper");
    if (previewSpinner) { previewSpinner.remove(); }
  }

  static getLeadInfo() {


    try {
      _kol.leads_instance.cachedCurrentLeadData().then(function(lead) {
        if (lead){
          var evt = new CustomEvent("kol:knownlead", { detail: lead });
          window.dispatchEvent(evt);
          return;
        }
      });
    } catch (e) {
      //why did we need this.
    }
    //TODO: Why do we need a try catch here? Why not return null if share or current lead not found?
    try {
      _kol.leads_instance.cachedShareLeadData().then(function(sharelead) {
        if (sharelead){
          var evt = new CustomEvent("kol:knownsharelead", { detail: sharelead });
          window.dispatchEvent(evt);
          return;
        }

      })
      .catch(function(error) {
        console.log(error);
      });
 
    } catch (e) {
      console.log(e);
    }

    var evt = new CustomEvent('kol:unknownlead', { detail: null });
    
    window.dispatchEvent(evt);

    //TODO: Should this stay expanded all the time? or set a timeout to shrink it down?  Icon or avatar in small mode?
  }

  static handleLeadDataSubmit(data) {
    
    console.log(data);
    const conversionData = _kol.analytics_instance.getConversionData();
    const apiData = extend({}, data.formData, conversionData);

    //TODO - scott callback?
    _kol.leads_instance.addLead(apiData).then(function(response) {
      var evt = new CustomEvent('kol:knownlead', { detail: response.data });
      window.dispatchEvent(evt);
    });
    //this.debug(`Tag Data ${apiData}`);
  }

  static postLeadToContestFrame(lead){
    let contestFrame = document.getElementById("kol-sidebar-contest-frame");
    let data = {
      type: "kol:lead",
      lead: lead
    };
    contestFrame.contentWindow.postMessage(data, "*");
  }

  static postNoLeadToContestFrame(lead){
    console.log('posting no lead to frame');
    let contestFrame = document.getElementById("kol-sidebar-contest-frame");
    let data = {
      type: "kol:nolead"
    };
    contestFrame.contentWindow.postMessage(data, "*");
  }

  /* Setup Event Listeners */

  static setupEventListeners() {
    console.log("Adding Event Handlers");
    //update onpageproperties
    window.addEventListener('kol:onpage:sidebar:build', function (event) {
      console.log(event);
      _kol.onPageActions_instance.options = extendOverwrite(
        {},
        _kol.onPageActions_instance.options,
        event.detail
      );
      KOLOnPage.writeContestFrame(event.detail);
      KOLOnPage.writeButtonFrame(event.detail);
    });

    window.addEventListener('kol:knownlead', function (event) {
      console.log(event);
      let lead = event.detail;
      console.log("Posting KnownLead");
      console.log(lead);
      KOLOnPage.postLeadToContestFrame(lead);
      let givenName = lead.given_name ? lead.given_name : "";
      KOLOnPage.updateShowNotification({
        //need default text and image values set on parent.
        image: lead.avatar,
        imageAlt: lead.username,
        text: `${_kol.onPageActions_instance.options.content_notificationSignedIn}&nbsp;${givenName} - ${lead.contest_score} ${_kol.onPageActions_instance.options.content_pointsWord}`
      });

    });

    window.addEventListener('kol:unknownlead', function (event) {
      console.log(event);
      let lead = event.detail;
      console.log("Posting No Lead");
      console.log(lead);
      KOLOnPage.postNoLeadToContestFrame(lead);
      KOLOnPage.updateShowNotification({
        //need default text and image values set on parent.
        image: "",
        imageAlt: "",
        text: _kol.onPageActions_instance.options.content_notificationSignedOut
      });

    });



    window.addEventListener('kol:knownsharelead', function (event) {
      let shareLead = event.detail;
      //TODO: POst to Contest Frame incase we add share stuff there. 
      console.log("Posting ShareLead");
      console.log(shareLead);
      KOLOnPage.updateShowNotification({
        image: shareLead.avatar,
        imageAlt: shareLead.username,
        text:
          shareLead.given_name + " " + _kol.onPageActions_instance.options.content_notificationInvitedBy
      });
    });

    window.addEventListener("message", function(event) {
      let currentHost = window.location.protocol + "//:" + window.location.host;
      if (event.origin != currentHost) {
        // something from an unknown domain, let's ignore it
        console.log(event);
        if (event.data && event.data.type) {
          const type = event.data.type;
          console.log(type);
          if (type == "kol:submit") {
            KOLOnPage.handleLeadDataSubmit(event.data);
          }
        }

        return;
      }
    });
  }

  static writeButtonFrame(options){
    let buttonFrame = document.getElementById("kol-sidebar-button-frame");
    let kolTemplates = new KOLOneTemplates();
    buttonFrame.contentWindow.document.open();
    buttonFrame.contentWindow.document.write(
      kolTemplates.getButtonFrameContent(options)
    );
    buttonFrame.contentWindow.document.close();
  }

  static writeContestFrame(options) {
    let contestFrame = document.getElementById("kol-sidebar-contest-frame");
    let kolTemplates = new KOLOneTemplates();
    contestFrame.addEventListener("load", KOLOnPage.handleContestLoad, true);
    contestFrame.contentWindow.document.open();
    contestFrame.contentWindow.document.write(
      kolTemplates.getiContestFrameContent(options)
    );
    contestFrame.contentWindow.document.close();
    console.log('finished writing stuff to frame');
  }

  static hideNotificationBar() {
    if ( _kol.options.design_mode != true){
      let buttonFrame = document.getElementById("kol-sidebar-button-frame");
      let notice = buttonFrame.contentWindow.document.getElementById(
        "kol-notification"
      );
      buttonFrame.classList.remove("kol-show-notification");
      notice.classList.remove("kol-show-notification");
      storage.setItem(
        `kola.${_kol.options.campaignId}.notificationLastSeen`,
        Date.now()
      );
    }

  }

  static openSidebar(){
    const sidebarContest = document.querySelector(
      ".kol-sidebar-contest-frame-holder"
    );

    let buttonFrame = document.getElementById("kol-sidebar-button-frame");
    let wrapper = buttonFrame.contentWindow.document.getElementById(
      "kol-button-wrapper"
    );
    sidebarContest.classList.add("kol-contest-open");
    wrapper.classList.add("kol-contest-open");
    KOLOnPage.hideNotificationBar();
    //TODO: Figure out how to read the options in here for things like speed.
    fadeIn(sidebarContest, 500);
  }

  static closeSidebar(){
    const sidebarContest = document.querySelector(
      ".kol-sidebar-contest-frame-holder"
    );
    let buttonFrame = document.getElementById("kol-sidebar-button-frame");
    let wrapper = buttonFrame.contentWindow.document.getElementById("kol-button-wrapper");
    sidebarContest.classList.remove("kol-contest-open");
    wrapper.classList.remove("kol-contest-open");
    //TODO: Figure out how to read the options in here for things like speed.
    fadeOut(sidebarContest, 500);
  }

  static updateShowNotification(options) {
    let buttonFrame = document.getElementById("kol-sidebar-button-frame");
    let notice = buttonFrame.contentWindow.document.getElementById("kol-notification");
    let notificationImage = buttonFrame.contentWindow.document.getElementById("kol-notification-image");
    let notificationText = buttonFrame.contentWindow.document.getElementById("kol-notification-text");
    let imageHolder = buttonFrame.contentWindow.document.getElementsByClassName("kol-button-notification-image");
    
    if (options.image) {
      Object.assign(notificationImage, {
        src: options.image,
        alt: options.imageAlt
      });     
      Object.assign(imageHolder[0].style, {
        display: "inline",
      });

      notificationText.classList.add("with-kol-image");

    } 
    else {
      Object.assign(imageHolder[0].style, {
        display: "none"
      });
      notificationText.classList.remove("with-kol-image");
    }

    notificationText.innerHTML = options.text;

    buttonFrame.classList.add("kol-show-notification");
    notice.classList.add("kol-show-notification");
  }

  /* Template Functions */
  sideBar(options) {
    this.options = options;
    ready(function() {
      let kolTemplates = new KOLOneTemplates();

      console.log("launching sidebar");
      console.log(options);

      // create objects we need.
      const sidebarContainer = document.createElement("div");
      //TODO add instance to ID if one exists.
      Object.assign(sidebarContainer, {
        id: "kol_sidebar_container",
        class: "kol-sidebar-container"
      });

      Object.assign(sidebarContainer.style, {
        bottom: 0,
        height: "calc(100% - 88px)",
        position: "fixed",
        right: 0,
        width: "380px"
      });

      let frameHolder = stringToHTML(kolTemplates.getButtonFrameMarkup({}));
      let buttonFrame = frameHolder.getElementsByClassName(
        "kol-sidebar-button-frame"
      )[0];

      let contestHolder = stringToHTML(
        kolTemplates.getContestFrameMarkup(options)
      );

    
      sidebarContainer.appendChild(frameHolder.firstElementChild);
      sidebarContainer.appendChild(contestHolder.firstElementChild);

      document.body.appendChild(sidebarContainer);

      KOLOnPage.writeButtonFrame(options);


      let openBtn = buttonFrame.contentWindow.document.getElementById(
        "kol-button-wrapper"
      );
      openBtn.onclick = function() {
        const sidebarContest = document.querySelector(
          ".kol-sidebar-contest-frame-holder"
        );
        if (sidebarContest.classList.contains("kol-contest-open")) {
          KOLOnPage.closeSidebar();
        } else {
          KOLOnPage.openSidebar();
        }
      };

      

      KOLOnPage.writeContestFrame(options);

      KOLOnPage.setupEventListeners();


   

      if (_kol.options.design_mode == true){
        KOLOnPage.openSidebar();
      }

      // TODO: use current_lead... if so.. send to their thanks page.
      //TODO: Why try catch here?
      //TOOD: This is advanced mode - read options and set a starting page.
      // try{
      //   //for debuging
      //   _kol.setConverionId("15ZHF1");
      //   _kol.currentLead().then(function (lead) {

      //     contestFrame.src = lead.thankyou_url;

      //   }).catch(function (e) {
      //     contestFrame.src = this.getiFrameEmbedSrcUrl({
      //       domain: "embed.kickoffpages.test",
      //       protocol: "http",
      //       pageID: 280172
      //     });

      //   });
      // }
      // catch(e){
      //   contestFrame.src = this.getiFrameEmbedSrcUrl({
      //     domain: "embed.kickoffpages.test",
      //     protocol: "http",
      //     pageID: 280172
      //   });
      // }

    });
  }
}
