import "promise-polyfill/src/polyfill";
import "whatwg-fetch";
import KA from "./analytics";
import Leads from "./leads";
import CampaignOptions from "./campaign_options";
import KOLTag from "./tag";
import KOLOnPage from "./on_page_actions";
//import snippetUI from "./snippetUI/snippetUI";

import { captureShopifyCheckout, addCustomFieldsToCart } from "./shopify";

require("es6-symbol/implement");

class KOL {
  constructor(campaignId, optionOverrides = {}, analytics = null) {
    //TODO: Settings for "dumb Mode" where you can just run snippets.

    this.options = optionOverrides;
    this.options.campaignId = campaignId;
    this.options_instance = new CampaignOptions(this.options);

    // this.options_instance.cachedOptionsData().then(function (options) {
    //   this.options = options;
    //   this.debug = true;

    this.analytics_instance = analytics || new KA(this.options);
    if (this.analytics_instance.options.trackView) {
      this.analytics_instance.trackView();
    }

    this.leads_instance = new Leads(this.options, this.analytics_instance);

    if (window.Shopify && Shopify.checkout) {
      captureShopifyCheckout(this.options, Shopify.checkout);
    }
    addCustomFieldsToCart(campaignId);

    this.onPageActions_instance = new KOLOnPage(this.options.onPageActions);

    //snippetUI.buildAll(this.options);

    this.tag_instance = new KOLTag(this.options, this.analytics_instance);
    // });
  }

  trackView() {
    return this.analytics_instance.trackView();
  }

  tag(...args) {
    if (args.length === 2) {
      return this.tag_instance.addLeadToTagOld(...args);
    }
    return this.tag_instance.addLeadToTag(...args);
  }

  referral(...args) {
    if (args[0].tagId) {
      return this.tag_instance.addReferral(...args);
    }
    return this.tag_instance.addReferral({ tagId: args[0] });
  }

  currentLead(leadId) {
    return this.leads_instance.currentLead(leadId);
  }

  shareLead(leadId) {
    return this.leads_instance.shareLead(leadId);
  }

  static fetchKolScriptAttribute(optionName) {
    const scriptTag = document.getElementById("koljs");
    if (scriptTag) {
      return scriptTag.getAttribute(optionName);
    }
    return null;
  }
}

window.KOL = KOL;
const campaignId = KOL.fetchKolScriptAttribute("data-campaign-id");

if (campaignId) {
  window._kol = new KOL(campaignId, window.kolOptions); // eslint-disable-line no-underscore-dangle, max-len
} else {
  const message =
    "KOL.js did not create _kol variable. Element with id koljs and attribute data-campaign-id were not found";
  const messageFN = console.warn || console.log;
  messageFN(message);
}
