
export const onSubSelector = (parentSelector, childSelector, eventType, callbackFunction) => { // eslint-disable-line import/prefer-default-export, max-len
  document.querySelector(parentSelector).addEventListener(eventType, (event) => {
    if (event.target.matches(childSelector)) {
      return callbackFunction(event);
    }
    return null;
  });
};

export const ready = (fn) => {
  if (document.attachEvent ? document.readyState === 'complete' : document.readyState !== 'loading') {
    fn();
  } else {
    document.addEventListener('DOMContentLoaded', () => {
      fn();
    });
  }
};

export const trigger = (selector, eventName, data) => {
  let event = null;
  if (typeof window.CustomEvent === 'function') {
    event = new CustomEvent(eventName, { detail: data });
  } else {
    event = document.createEvent('CustomEvent');
    event.initCustomEvent(eventName, true, true, data);
  }

  const el = document.querySelector(selector);
  if (el) {
    el.dispatchEvent(event);
  }
};

export const on = (selector, eventName, eventHandler) => {
  ready(() => {
    const el = document.querySelector(selector);
    el.addEventListener(eventName, eventHandler);
  });
};
