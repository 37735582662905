import Cookies from 'js-cookie';

export default class KOLCookie {
  static get(key) {
    return Cookies.get(key);
  }

  static set(key, value) {
    return Cookies.set(key, value, { expires: 1000, path: '/' });
  }

  static session(key, value) {
    return Cookies.set(key, value, { path: '/' });
  }
}
