
const debugLoader = (shouldDebug = false) => {
  if (shouldDebug) {
    return (message, data) => {
      if (data) {
        console.log(message, data);
      } else {
        console.log(message);
      }
    };
  }
  return () => {};
};

export default debugLoader;
