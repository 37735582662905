export default class KOLFormBuilder{
  constructor(options) {
    this.options = options != null ? options : {};
  }

  render_inline = function () {
    var element;
    return ((function () {
      var i, len, ref, results;
      ref = this.options.form_elements;
      results = [];
      for (i = 0, len = ref.length; i < len; i++) {
        element = ref[i];
        results.push(this.render_element(element));
      }
      return results;
    }).call(this)).join("\n");
  };

  render_element = function (el) {
    var element;
    element = (function () {
      switch (el.type) {
        case 'email':
          return this.render_email_element(el);
        case 'text':
          return this.render_text_element(el);
        case 'tel':
          return this.render_tel_element(el);
        case 'textarea':
          return this.render_textarea_element(el);
        case 'checkbox':
          return this.render_checkbox_element(el);
        case 'choice':
          return this.render_select_element(el);
        default:
          return this.render_text_element(el);
      }
    }).call(this);
    return element;
  };

  render_email_element = function (el) {
    var input;
    input =
      '<input aria-label="' +
      el.label +
      '" type="email" class="' +
      this.css_classes("email", true) +
      '" id="' +
      el.name +
      '" name="' +
      el.name +
      '" placeholder="' +
      el.placeholder +
      '" ' +
      this.requiredInput(el) +
      '></input>';
    return this.wrap_in_form_group_and_label(input, el);
  };

  render_tel_element = function (el) {
    var input;
    input =
      '<input aria-label="' +
      el.label +
      '" type="tel" class="' +
      this.css_classes("text", el.required) +
      '" id="' +
      el.name +
      '" name="' +
      el.name +
      '" placeholder="' +
      el.placeholder +
      
      '" '+ this.requiredInput(el) +'></input>';
    return this.wrap_in_form_group_and_label(input, el);
  };

  render_text_element = function (el) {
    var input;
    input =
      '<input aria-label="' +
      el.label +
      '" type="text" class="' +
      this.css_classes("text", el.required) +
      '" id="' +
      el.name +
      '" name="' +
      el.name +
      '" placeholder="' +
      el.placeholder +
      '"' +
      this.requiredInput(el) +
      "></input>";
    return this.wrap_in_form_group_and_label(input, el);
  };

  render_textarea_element = function (el) {
    var input;
    input =
      '<textarea aria-label="' +
      el.label +
      '" class="' +
      this.css_classes("textarea", el.required) +
      '" id="' +
      el.name +
      '" rows="30" name="' +
      el.name +
      '" placeholder="' +
      el.placeholder +
      '" ' +
      this.requiredInput(el) +
      "></textarea>";
    return this.wrap_in_form_group_and_label(input, el);
  };

  render_checkbox_element = function (el) {
    return (
      '<label class="kol_checkbox_label"><input aria-label="' +
      el.label +
      '" type="checkbox" class="' +
      this.css_classes("checkbox", el.required) +
      '" id="' +
      el.name +
      '" name="' +
      el.name +
      '" placeholder="' +
      el.placeholder +
      '"></input> ' +
      el.placeholder +
      "</lable>"
    );
  };

  render_select_element = function (el) {
    var input, option;
    input = "<select class=\"kol_element kol_select_element\" id=\"" + el.name + "\" name=\"" + el.name + "\">\n  " + ((function () {
      var i, len, ref, results;
      ref = el.options;
      results = [];
      for (i = 0, len = ref.length; i < len; i++) {
        option = ref[i];
        results.push(this.render_option(option, el));
      }
      return results;
    }).call(this)) + "\n</select>";
    return this.wrap_in_form_group_and_label(input, el);
  };

  render_option = function (option, el) {
    return "<option value='" + option + "'>" + option + "</option>";
  };

  wrap_in_form_group_and_label = function (input, el) {
    var f_group, srOnly;

    if (el.showLabel == true){
      srOnly = "show-label"
    }
    else{
      srOnly = "sr-only";
    }

    f_group = "<div class=\"form-group kol-form-group\">\n  {{label}}\n  " + input + "\n</div>";
    if (el.label.length > 0) {
      f_group = f_group.split('{{label}}').join("<label class='kol-form-label "+ srOnly +"' for='" + el.name + "'>" + el.label + "</label>");
    } else {
      f_group = f_group.split('{{label}}').join('');
    }
    return f_group;
  };

  requiredInput = function(element){
    if(element.required){
      return " required='true' "
    }
    else return ""
  }

  css_classes = function (type_of_element, required) {
    return "kol-form-control form-control input-md " + "kol_" + type_of_element + (required ? ' required' : '');
  };

  inline_css = function () {
    return "<style>\n  input[type=\"text\"].kol_elment , input[type=\"email\"].kol_element {\n    display: inline-block;\n    height: 36px;\n    padding: 4px 6px;\n    width:90%;\n    margin-top: 9px;\n    margin-bottom: 9px;\n    font-size: 13px;\n    line-height: 18px;\n    color: #555;\n    -webkit-border-radius: 3px;\n    -moz-border-radius: 3px;\n    border-radius: 3px;\n    vertical-align: middle;\n    background-color: #fff;\n    border: 1px solid #ccc;\n    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,0.075);\n    -moz-box-shadow: inset 0 1px 1px rgba(0,0,0,0.075);\n    box-shadow: inset 0 1px 1px rgba(0,0,0,0.075);\n    -webkit-transition: border linear 0.2s,box-shadow linear 0.2s;\n    -moz-transition: border linear 0.2s,box-shadow linear 0.2s;\n    -o-transition: border linear 0.2s,box-shadow linear 0.2s;\n    transition: border linear 0.2s,box-shadow linear 0.2s;\n\n  }\n\n  textarea.kol_element {\n    height: 90px;\n  }\n\n  label.kol_checkbox_label {\n      display: block;\n      padding-left: 15px;\n      text-indent: -15px;\n  }\n  input[type=\"checkbox\"].kol_element {\n      width: 13px;\n      height: 36px;\n      padding: 0;\n      margin:0;\n      position: relative;\n      top: -1px;\n      *overflow: hidden;\n  }\n</style>";
  };
}
