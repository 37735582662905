import Cookies from "./utils/cookies";
import { postData } from "./utils/data";
import { extend } from "./utils/objects";
import KOLResponses from "./response_callbacks";

const addCustomFieldsToCart = (campaignId) => {
  if (campaignId && window.location.pathname === "/cart") {
    const form = document.querySelector("form.cart");
    if (form) {
      const campaignIdInput = document.createElement("input");
      campaignIdInput.setAttribute("type", "hidden");
      campaignIdInput.setAttribute("name", "attributes[campaignId]");
      campaignIdInput.setAttribute("value", campaignId);
      form.appendChild(campaignIdInput);

      const kid = Cookies.get(`kola.${campaignId}.social_id`);
      if (kid) {
        const kidInput = document.createElement("input");
        kidInput.setAttribute("type", "hidden");
        kidInput.setAttribute("name", "attributes[kid]");
        kidInput.setAttribute("value", kid);
        form.appendChild(kidInput);
      }
    }
  }
};

const captureShopifyCheckout = (
  optionOverrides = {},
  shopifyData,
  delayRetry = false
) => {
  if (!shopifyData) {
    throw "No Shopify data supplied";
  }

  const defaultOptions = {
    shopify: {
      apiUrl: "https://app.kickofflabs.com/__shopify",
    },
  };

  const options = extend(
    {},
    defaultOptions,
    optionOverrides,
    window.kol_shopify_options || {}
  );

  const kid = Cookies.get(`kola.${options.campaignId}.social_id`);
  const {
    email,
    phone: phone_number,
    order_id,
    customer_id,
    total_price,
    subtotal_price,
  } = shopifyData;

  // Only try to send if we have an email and order id.
  // otherwise we just fail server side
  if (email && order_id) {
    //credit_card: { first_name, last_name }
    const shopifyDemoData =
      shopifyData.credit_card ||
      shopifyData.shipping_address ||
      shopifyData.billing_address;
    const first_name = shopifyDemoData && shopifyDemoData.first_name;
    const last_name = shopifyDemoData && shopifyDemoData.last_name;

    const kolData = {
      kid,
      campaignId: options.campaignId,
      email,
      phone_number,
      order_id,
      customer_id,
      total_price,
      subtotal_price,
      first_name,
      last_name,
    };

    try {
      console.log("Sending data to KickoffLabs");
      console.table(kolData);
      postData(options.shopify.apiUrl, kolData).then((lead_data) => {
        console.log("Data finished sending to KickoffLabs");

        let response = new KOLResponses({
          lead_data: lead_data,
        });

        response.execResponse();
      });
    } catch (error) {
      console.error(error);
    }
  } else if (delayRetry) {
    // Looks like we already did the fall back. Sorry, nothing else we can do
    console.log("Sorry, either email or order id is missing");
  } else {
    // Looks like there may be some extra data in window.cart_json.
    // This data is after the placement of our scripts.
    // We will attempt to wait a bit for the page to load.
    setTimeout(() => {
      const mergedShopifyData = extend({}, shopifyData, window.cart_json || {});
      return captureShopifyCheckout(optionOverrides, mergedShopifyData, true);
    }, 500);
  }
};

export { captureShopifyCheckout, addCustomFieldsToCart };
