const handleErrors = response => {
  if (!response.ok) {
    const errorMessage = `${response.statusText} (${response.status})`;
    console.error(errorMessage);
    throw Error(errorMessage);
  }
  return response;
};

const logOutput = response => {
  if (console.table) {
    console.table(response);
  } else {
    console.log(response);
  }
  return response;
};

export function postData(url, data) {
  return fetch(url, {
    body: JSON.stringify(data), // must match 'Content-Type' header
    cache: "no-cache",
    headers: {
      "content-type": "application/json",
      accept: "application/json"
    },
    method: "POST",
    mode: "cors"
  })
    .then(logOutput)
    .then(handleErrors)
    .then(response => response.json())
    .then(logOutput);
}

export const extractFormFields = elements =>
  [].reduce.call(
    elements,
    (data, element) => {
      if (
        element.name &&
        element.name !== "" &&
        element.value &&
        element.value !== ""
      ) {
        data[element.name] = element.value; // eslint-disable-line no-param-reassign
      }
      return data;
    },
    {}
  );

export function getData(url, mode = "cors") {
  console.log(`Fetching ${url}`);
  return fetch(url, {
    cache: "no-cache",
    headers: {
      "content-type": "application/json",
      accept: "application/json"
    },
    method: "GET",
    mode: mode
  })
    .then(logOutput)
    .then(handleErrors)
    .then(response => response.json())
    .then(logOutput);
}
