
const getQS = (name) => {
  const qs = name.replace(/[\[]/, "\\\[").replace(/[\]]/, "\\\]"); // eslint-disable-line no-useless-escape, quotes
  const regexS = `[\\?&]${qs}=([^&#]*)`;
  const regex = new RegExp(regexS);
  const results = regex.exec(window.location.href);
  if (results) {
    return decodeURIComponent(results[1].replace(/\+/g, ' '));
  }
  return null;
};

export default getQS;
