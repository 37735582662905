import { extend } from "./utils/objects";
import KA from "./analytics";
import { postData } from "./utils/data";
import debugLoader from "./utils/debugging";
import { trigger } from "./utils/events";
import { capturedFunctionNotificationName } from "./utils/globals";

export default class KOLTag {
  constructor(optionOverrides = {}, analytics = null) {
    this.options = extend(
      {},
      this.defaultOptions,
      optionOverrides,
      KOLTag.globalOverrides()
    );
    this.setupAnalytics(analytics);
    this.debug = debugLoader(this.options.debug);
    this.debug("Options From Tags", this.options);
  }

  defaultOptions = {
    tags: {
      apiUrl: "https://tags.kickofflabs.com"
    }
  };

  static globalOverrides() {
    return window.kol_tag_options || {};
  }

  setupAnalytics(analytics) {
    this.analytics = analytics || new KA(this.options.analytics);
    if (this.analytics.trackViews) {
      this.analytics.trackView();
    }
  }

  buildUrl(tagId, path) {
    return `${this.options.tags.apiUrl}/${this.options.campaignId}/${tagId}/${path}`;
  }

  addReferral(args) {
    this.debug("#addReferral");
    const { tagId, email, kid = this.defaultKid() } = args;
    if (email || kid) {
      //Can we just call /referral
      const url = this.buildUrl(tagId, `referral`);
      this.debug(`tagURL ${url}`);
      return postData(url, { email, kid }).then(response => {
        this.debug("Tag Response", response);
        return response;
      });
    }

    console.error("No kid was passed in and none could be derrived");
    return false;
  }

  defaultKid() {
    return this.analytics && this.analytics.user && this.analytics.user.kid;
  }

  addLeadToTag(args) {
    this.debug("#send!");
    const { tagId, ...data } = args;
    const conversionData = this.analytics.getConversionData();
    const apiData = extend({}, data, conversionData);
    this.debug(`Tag Data ${apiData}`);
    return this.executeAdd(tagId, apiData);
  }

  addLeadToTagOld(tagId, email) {
    this.debug("#sendOld");
    const conversionData = this.analytics.getConversionData();
    const apiData = extend({}, { email }, conversionData);
    this.debug(`Tag Data ${apiData}`);
    return this.executeAdd(tagId, apiData);
  }

  executeAdd(tagId, data) {
    this.debug("executeSend");
    const url = this.buildUrl(tagId, "add");
    this.debug(`tagURL ${url}`);
    return postData(url, data)
      .then(response => {
        this.debug("Tag Response", response);
        return response;
      })
      .then(response => {
        this.debug(`Triggering ${capturedFunctionNotificationName}`, response);
        trigger("body", capturedFunctionNotificationName, response.lead);
        return response;
      });
  }
}
