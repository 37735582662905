import { getData } from "./utils/data";
import { extend } from "./utils/objects";
import storage from "local-storage-fallback";

export default class Leads {
  constructor(optionOverrides, analytics) {
    this.options = extend({}, this.defaultOptions, optionOverrides);
    this.analytics = analytics;
  }

  defaultOptions = {
    leads: {
      apiUrl: "https://leads.kickofflabs.com"
    }
  };

  /*
   * Pass a lead ID (or just lookup current lead in cookies and get the cached CID data if it exists.
   * ageAllowed is how old you are willing to let the data be in seconds.
   * Returns a promise you can .then off of.
   */
  cachedCurrentLeadData(leadId = null, ageAllowed = 300) {

    if (this.options.debug && this.options.debugCurrentLead){
      return Promise.resolve(this.options.debugCurrentLead);
    }

    if (this.options.design_mode){
      return Promise.resolve(null)
    }

    let leadData = storage.getItem(`kola.${this.options.campaignId}.cid.data`);

    if (leadData) {
      leadData = JSON.parse(leadData);
      if (Date.now() - leadData.queryDateTime <= ageAllowed * 1000) {
        return Promise.resolve(leadData);
      }
    }
    return _kol.currentLead(leadId);
  }

  currentLead(leadId = null) {
    const leadToLookUp = Leads.checkForLead(leadId, this.analytics.user.cid);
    return this.fetch(leadToLookUp).then(function(leadData) {
      _kol.leads_instance.cacheLeadData(leadData, "cid");
      return leadData;
    });
  }

  /*
   * Pass a lead ID (or just lookup current lead in cookies and get the cached CID data if it exists.
   * ageAllowed is how old you are willing to let the data be in seconds.
   */
  cachedShareLeadData(leadId = null, ageAllowed = 300) {

    if (this.options.debug && this.options.debugShareLead) {
      return Promise.resolve(this.options.debugShareLead);
    }

    if (this.options.design_mode) {
      return Promise.resolve(null);
    }

    let leadData = storage.getItem(`kola.${this.options.campaignId}.kid.data`);
    if (leadData) {
      leadData = JSON.parse(leadData);
      if (Date.now() - leadData.queryDateTime <= ageAllowed * 1000) {
        return Promise.resolve(leadData);
      }
    }
    return _kol.shareLead(leadId);
  }

  shareLead(leadId = null) {
    const leadToLookUp = Leads.checkForLead(leadId, this.analytics.user.kid);
    return this.fetch(leadToLookUp).then(function(leadData) {
      _kol.leads_instance.cacheLeadData(leadData, "kid");
      return leadData;
    });
  }

  static checkForLead(leadId, analyticsLookUp) {
    const leadToLookUp = leadId || analyticsLookUp;
    if (!leadToLookUp) {
      throw Error("No leadId supplied or available to lookup");
    }
    return leadToLookUp;
  }

  fetch(leadId) {
    const url = this.buildUrl(leadId);

    return getData(url);
  }

  buildUrl(leadId) {
    return `${this.options.leads.apiUrl}/lead/${this.options.campaignId}/${leadId}`;
  }

  cacheLeadData(leadData, type = "cid") {
    leadData["queryDateTime"] = Date.now();
    console.log("caching lead data: " + type);
    storage.setItem(
      `kola.${this.options.campaignId}.${type}.data`,
      JSON.stringify(leadData)
    );
  }

  //TODO: Should this be in here? Passing KID referral data? Set API URL for testing locally? Callback?
  addLead(leadData) {
    return fetch(
      `${this.options.leads.apiUrl}/lead/${this.options.campaignId}/`,
      {
        body: JSON.stringify(leadData),
        cache: "no-cache",
        headers: {
          "content-type": "application/json"
        },
        method: "POST",
        mode: "cors"
      }
    )
      .then(
        (function(_this) {
          return function(response) {
            console.table(response);
            return response;
          };
        })(this)
      )
      .then(
        (function(_this) {
          return function(response) {
            if (response.status < 500) {
              return response.json().then(function(data) {
                return {
                  data: data,
                  status: response.status,
                  statusText: response.statusText
                };
              });
            } else {
              return {
                status: response.status,
                statusText: response.statusText
              };
            }
          };
        })(this)
      )
      .then(
        (function(_this) {
          return function(response) {
            var errorMessage;
            if (response.status !== 200) {
              errorMessage =
                (response.data && response.data.message) || response.statusText;
              console.error(errorMessage);
              throw Error(errorMessage);
            }
            return response;
          };
        })(this)
      )
      .then(
        (function(_this) {
          return function(response) {
            var event = new CustomEvent("kol:success", {
              data: response.data,
              statusText: response.statusText
            });
            window.document.dispatchEvent(event);
            return response;
          };
        })(this)
      )
      .then(
        (function(_this) {
          return function(response) {
            _kol.analytics_instance.captureSignUp({
              id: response.data.social_id
            });
            _kol.leads_instance.cacheLeadData(response.data, "cid");
            return response;
          };
        })(this)
      )
      ["catch"](
        (function(_this) {
          return function(err) {
            console.error(err);
            if (window.bugsnagClient) {
              window.bugsnagClient.notify(err);
            }
            return alert(
              "Sorry, we were not able to sign you up at this time. Please try again."
            );
          };
        })(this)
      );
  }
}
