import { getData } from "./utils/data";
import { extend, extendOverwrite } from "./utils/objects";
import storage from "local-storage-fallback";

export default class CampaignOptions {
  constructor(options) {
    this.options = extend({}, this.defaultOptions, options);
  }

  defaultOptions = {
      optionsBaseURL: "http://options.kickoffpages.test/",
  };

  /*
   * Pass a Campaign ID (or just lookup current options in cookies and get the cached  data if it exists.
   * ageAllowed is how old you are willing to let the data be in seconds.
   * Returns a promise you can .then off of.
   */
  cachedOptionsData(ageAllowed = 300) {
    
    if (this.options.design_mode) {
      return Promise.resolve({});
    }

    let optionsData = storage.getItem(
      `kola.${this.options.campaignId}.options.data`
    );

    if (optionsData) {
      optionsData = JSON.parse(optionsData);
      if (Date.now() - optionsData.queryDateTime <= ageAllowed * 1000) {
        return Promise.resolve(optionsData);
      }
    }
    return this.currentOptions();
  }

  currentOptions() {
    return this.fetch(this.options.campaignId).then(function (optionsData) {
      let newOptions = extend({}, optionsData, _kol.options_instance.options);
      _kol.options_instance.cacheOptionsData(newOptions, "options");
      return newOptions;
    });
  }

  fetch() {
    const url = this.buildUrl();
    return getData(url);
  }

  buildUrl() {
    //todo: need to make this dynamic for production;
    return `${this.options.optionsBaseURL}${this.options.campaignId}/`;
  }

  cacheOptionsData(optionsData, type = "options") {
    optionsData["queryDateTime"] = Date.now();
    console.log("caching option data: " + type);
    storage.setItem(
      `kola.${this.options.campaignId}.${type}.data`,
      JSON.stringify(optionsData)
    );
  }
}
