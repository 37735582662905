/**
 * Function to fade an element
 * @param  {HTMLElement} element
 * @param  {Number} from
 * @param  {Number} to
 * @param  {Number} [duration=300]
 */

 const STYLE = {
   SHOW: "block",
   HIDE: "none"
 };
 
export const fade = (element, from, to, duration) => {
  const start = window.performance.now();

  if (from === -1) {
    from = 1 * window.getComputedStyle(element).getPropertyValue("opacity");
  }

  duration = duration || TIME.FADE.DEFAULT;
  element.style.display = STYLE.SHOW;

  window.requestAnimationFrame(function step(timestamp) {
    const progress = timestamp - start;
    element.style.opacity = from + (progress / duration) * (to - from);

    if (progress < duration) {
      window.requestAnimationFrame(step);
    } else if (element.style.opacity <= 0) {
      element.style.display = STYLE.HIDE;
    }
  });
};

/**
 * Fade in shorthand
 * @param  {HTMLElement} element
 * @param  {Number|undefined} duration
 */
export const fadeIn = (element, duration) => {
  fade(element, -1, 1, duration);
};

/**
 * Fade out shorthand
 * @param  {HTMLElement} element
 * @param  {Number|undefined} duration
 */
export const fadeOut = (element, duration) => {
  fade(element, -1, 0, duration);
};
