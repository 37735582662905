import merge from 'deepmerge';

export const extend = (...objects) => merge.all([...objects]); // eslint-disable-line import/prefer-default-export, max-len

export function extend2(...objects) {
  return merge.all([...objects]);
}

export function extendOverwrite(...objects){

  const overwriteMerge = (destinationArray, sourceArray, options) => sourceArray;
  return merge.all(
    [...objects],
    { arrayMerge: overwriteMerge }
  );

}


